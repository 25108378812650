/*** Adjusted color swatches ***/
// Adjusted USWDS swatches
$theme-color-primary-darker: #64307E;
$theme-color-accent-cool: #E070A3;

// New USWDS swatches
$unity-pink: #D93778;
$unity-orange-adj: #E07616;
$unity-blue-adj: #6ACDE8;
$unity-orange-active: #D34200;
$unity-yellow: #FAE64C;
$unity-purple: #7E4E96;

// Setting up the Barlow Condensed font 
$theme-typeface-tokens: (
  barlow-condensed: (
    display-name: "BarlowCondensed",
    cap-height: 364px
  )
) !default;

@font-face {
    font-family: BarlowCondensed;
    src: url(../fonts/barlowcondensed/BarlowCondensed-Bold.ttf);
}

@font-face {
    font-family: PublicSans;
    src: url(../fonts/public-sans/PublicSans-Light.ttf);
}

// $theme-font-type-cond-custom-src: $theme-font-barlow-condensed !default;

// $theme-font-serif-custom-stack: BarlowCondensed;
// $theme-font-sans-custom-stack: BarlowCondensed;
$theme-font-type-serif: "barlow-condensed";
$theme-font-type-sans: "public-sans";

.bg-white {
    background-color: white;
}

.bg-unity-blue {
    background-color: $unity-blue-adj;
}

.text-white {
    color: white;
}

.bg-unity-yellow {
    background-color: $unity-yellow;
}

.text-accent-cool {
    color: $theme-color-accent-cool;
}

.bg-unity-purple {
    background-color: $unity-purple !important;
}

.text-barlow-heading {
    font-family: BarlowCondensed;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.text-barlow-title {
    font-family: BarlowCondensed;
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 32px;
    letter-spacing: -0.015em;
    text-transform: uppercase;
}

.text-barlow-logo {
    font-family: BarlowCondensed;
    font-style: normal; 
    font-weight: bold;
    font-size: 32px;
    line-height: 38px;
    text-transform: uppercase;
}

.text-primary-darker {
    color: $theme-color-primary-darker !important;
}

.bg-primary-darker {
    background-color: $theme-color-primary-darker;
}

.text-unity-blue {
    color: $unity-blue-adj !important;
}

.bg-unity-pink {
    background-color: $unity-pink;
}

.bg-unity-about {
    background-image: url("../img/_A3A3659_CMYK\ 1.png") !important;
}

.bg-unity-contact {
    background-image: url("../img/_A3A3388_CMYK 1.png") !important;
}

.img-circular {
    border-radius: 50%;
}

.agency-grid {
    display: flex;
    flex-direction: row column;
    justify-content: flex-start;
    align-items: flex-end;
    flex-wrap: nowrap;

    a {
        display: inline-block;
    }
}

.agency-item {
    max-height: 80px;
    max-width: 220px;
    margin-left: 50px;
    margin-right: 40px;
}

.agency-adjustment {
    margin-top: 50px;
}

.agency-adjustment-first {
    margin-left: 0px;
    margin-right: 30px;
}

.footer-text {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
}

.footer-padding {
    margin-left: 220px;
    padding-top: 30px;
}

.usa-footer__return-to-top {
    background-color: transparent;
}

.wrap-header {
    height: auto !important;
    overflow: hidden;
    margin-bottom: 0px;
}

.banner-adjustment {
    height: 22px;
    min-height: 22px !important;
    max-height: 24px !important;
}

.usa-button {
    background-color: $unity-pink !important;
}

.usa-button:hover {
    background-color: $unity-orange-adj !important;
}

.usa-button:active {
    background-color: $unity-orange-active !important;
}

.usa-prose {
    font-family: PublicSans !important;
}

.usa-menu-btn {
    background-color: $unity-orange-active !important;
}

.usa-menu-btn:hover {
    background-color: $unity-orange-active !important;
}

@media (max-width: 370px) {
    .govbanner {
        visibility: hidden;
    }
}

@media (min-width: 910px)
{
    .usa-nav__link::after {
        background-color: $theme-color-primary-darker !important;
    }

    .usa-nav__link:hover {
        color: $theme-color-primary-darker !important;
    }

    .nowrap {
        white-space: nowrap !important;
    }
}

@media (max-width: 910px) {
    .mobile-menu {
        background-color: $unity-orange-active !important;
    }

    .usa-nav__link {
        color: white !important;
    }

    .usa-nav__link:hover {
        color: $unity-orange-active !important;
    }
    
    .usa-nav__primary-item {
        border: none !important;
    }

    .usa-nav__primary .usa-current::after {
        background-color: $unity-orange-adj !important;
    }

    .usa-nav__primary-item:hover {
        color: $unity-orange-adj !important;
    }

    .usa-menu-btn {
        align-self: flex-start;
    }

    .usa-accordion {
        height: 100%;
        .usa-banner__header {
            min-height: auto!important;
            height:100%;
            padding-bottom: 0!important;
            padding-top: 0!important;
    
            .usa-banner__inner {
                height: 100%;
                align-items: center;
            }

        }
    }
    
}

@import '../../../node_modules/uswds/dist/scss/uswds.scss';
